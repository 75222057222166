import React from 'react';
import Card from '@material-ui/core/Card';
import { Title, SimpleForm, FileInput, FileField, required } from 'react-admin';
import UploadFileToolbar from 'components/UploadFileToolbar';
import Icon from '@material-ui/icons/CloudUpload';

export const UploadIcon = Icon;

export const UploadList = () => (
    <Card>
        <Title title="Upload" />
        <SimpleForm toolbar={<UploadFileToolbar source="file_url" redirectTo="/cars" />}>
            <FileInput source="file" label="File" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" validate={required()}>
                <FileField source="src" title="title"></FileField>
            </FileInput>
        </SimpleForm>
    </Card>
);

export default UploadList;