import React from "react";
import { DeleteButton } from "react-admin";
import withWidth from "@material-ui/core/withWidth";
import { withStyles } from "@material-ui/core/styles";
import MuiToolbar from "@material-ui/core/Toolbar";
import compose from "recompose/compose";
import classnames from "classnames";
import UploadSaveButton from "./UploadSaveButton";

const styles = {
  mobileToolbar: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    padding: "16px",
    width: "100%",
    boxSizing: "border-box",
    flexShrink: 0,
    backgroundColor: "white",
    zIndex: 2
  },
  defaultToolbar: {
    flex: 1,
    display: "flex",
    justifyContent: "space-between",
    margin: "0 24px 12px"
  }
};

const UploadToolbar = ({
  source,
  resource,
  record,
  invalid,
  pristine,
  redirectTo,
  saving,
  submitOnEnter,
  handleSubmit,
  handleSubmitWithRedirect,
  basePath,
  classes,
  className,
  width,
  ...rest
}) => {
  return (
    <MuiToolbar
      className={classnames(
        { [classes.mobileToolbar]: width === "xs" },
        className
      )}
      disableGutters
      {...rest}
    >
      <div className={classes.defaultToolbar}>
        <UploadSaveButton
          basePath={basePath}
          source={source}
          resource={resource}
          record={record}
          invalid={invalid}
          pristine={pristine}
          redirectTo={redirectTo}
          saving={saving}
          submitOnEnter={submitOnEnter}
          handleSubmit={handleSubmit}
        />
        {record && typeof record.id !== "undefined" && (
          <DeleteButton
            basePath={basePath}
            record={record}
            resource={resource}
          />
        )}
      </div>
    </MuiToolbar>
  );
};

const enhance = compose(
  withWidth(),
  withStyles(styles)
);
export default enhance(UploadToolbar);
