import React from 'react';
import {
  List,
  Edit,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  SimpleForm,
  NumberInput,
  required,
  SaveButton,
  Toolbar,
} from 'react-admin';
import Icon from '@material-ui/icons/Settings';
import Title from './Title';

export const SettingIcon = Icon;

const PostEditToolbar = props => (
  <Toolbar {...props} >
      <SaveButton />
  </Toolbar>
);

export const SettingList = props => (
  <List title="Settings" bulkActionButtons={false} {...props}>
    <Datagrid>
      <DateField source="created_at" showTime={true} options={{ year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' }}  />
      <DateField source="updated_at" showTime={true} options={{ year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' }}  />
      <TextField source="wearnes_margin" />
      <TextField source="owner_adjustment" />
      <TextField source="mileage_adjustment" label="Mileage adjutment cost" />
      <TextField source="master_adjustment" />
      <EditButton />
    </Datagrid>
  </List>
);

export const SettingEdit = props => (
  <Edit title={<Title />} {...props}>
    <SimpleForm toolbar={<PostEditToolbar />}>
      <NumberInput source="wearnes_margin" validate={required()} />
      <NumberInput source="owner_adjustment" validate={required()} />
      <NumberInput source="mileage_adjustment" validate={required()} label="Mileage adjutment cost" />
      <NumberInput source="master_adjustment" validate={required()} />
    </SimpleForm>
  </Edit>
);
