import React from 'react';
import {
  List,
  Create,
  Edit,
  Datagrid,
  DateField,
  TextField,
  TextInput,
  SimpleForm,
  required,
} from 'react-admin';
import Icon from '@material-ui/icons/DirectionsCar';
import Title from './Title';
import { ListActions, ListFilter } from './ListActions';

export const CarModelIcon = Icon;

export const CarModelList = props => (
  <List filters={<ListFilter />} actions={<ListActions />} title="Models" bulkActionButtons={false} {...props}>
    <Datagrid>
      <TextField source="name" />
      <DateField source="created_at" showTime={true} options={{ year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' }}  />
      <DateField source="updated_at" showTime={true} options={{ year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' }} />
    </Datagrid>
  </List>
);

export const CarModelCreate = props => (
  <Create title={'New Model'} {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required()} />
    </SimpleForm>
  </Create>
);

export const CarModelEdit = props => (
  <Edit title={<Title />} {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required()} />
    </SimpleForm>
  </Edit>
);
