import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Button
  // TextField,
  // Typography,
  // FormHelperText,
  // InputAdornment
} from "@material-ui/core";
import classNames from "classnames";

const styles = theme => ({
  root: {
    width: "100%"
  },
  formControl: {
    margin: theme.spacing.unit
  },
  buttons: {
    marginTop: 20
  },
  labelW: {
    display: "block",
    width: "100%",
    color: "#474747",
    fontSize: "0.875em",
    letterSpacing: "-0.17px",
    textAlign: "left",
    paddingTop: 15,
    marginTop: 15,
    marginBottom: 5
  },
  carmodelW: {
    color: "#000000",
    fontSize: "1.375em",
    fontWeight: "700",
    letterSpacing: "-0.45px",
    margin: "15px 0"
  },
  buttonW: {
    minWidth: 160
  },
  borderTopW: {
    borderTop: "1px solid #DDDEE0"
  },
  inputW: {
    backgroundColor: "#FFF",
    "& div & fieldset": {
      borderColor: "rgba(0, 0, 0, 0)"
    }
  },
  buttonReset: {
    backgroundColor: "#181818",
    color: "#FFF",
    "&:hover": {
      backgroundColor: "#464646"
    }
  },
  containerResult: {
    display: "block",
    margin: "20px auto 40px",
    padding: "20px 20px 0",
    backgroundColor: "#FFF",
    "& label": {
      display: "block",
      width: "100%",
      color: "#8E8E8E",
      fontSize: "0.875em",
      letterSpacing: "-0.17px",
      textAlign: "left"
    },
    "& h3": {
      color: theme.palette.primary.main
    },
    "@media (max-width:480px)": {
      margin: "20px auto",
      padding: "0",
      backgroundColor: "transparent",
      "& h3": {
        margin: "10px 0"
      }
    }
  },
  containerDetail: {
    display: "block",
    padding: "20px 0",
    "& label": {
      display: "block",
      width: "100%",
      color: "#8E8E8E",
      fontSize: "0.875em",
      letterSpacing: "-0.17px",
      textAlign: "left"
    },
    "& h4": {
      color: "#000",
      letterSpacing: "-0.2px",
      fontWeight: 600,
      fontSize: 16,
      margin: "0 0 10px"
    }
  }
});

class EstimateResult extends Component {
  handleInput = input => event => {
    this.props.onChoose(input, event.target.value);
  };

  formatMoney(string) {
    return parseInt(string).toLocaleString("en-SG", {
      style: "currency",
      currency: "SGD"
    });
  }

  renderContainer() {
    const { results, classes, values } = this.props;

    return (
      <form className={classes.root} autoComplete="off">
        <div className={classes.containerResult}>
          <Grid container spacing={24}>
            <Grid item xs={12} sm={4}>
              <label>Recommended Value</label>
              <h3>{this.formatMoney(results.valueCar)}</h3>
            </Grid>
            <Grid item xs={12} sm={4}>
              <label>Annual Depreciation</label>
              <h3>{this.formatMoney(results.annualDep)}</h3>
            </Grid>
          </Grid>
        </div>
        <div
          className={classNames(classes.containerDetail, classes.borderTopW)}
        >
          <Grid container spacing={24}>
            <Grid item xs={12} sm={6}>
              <h4>Car Details</h4>
              <Grid container spacing={24}>
                <Grid item xs={6} sm={4}>
                  <label>Car Model</label>
                  <h4>{values.carModelName}</h4>
                </Grid>
                <Grid item xs={6} sm={4}>
                  <label>Registration Date</label>
                  <h4>
                    {values.regDay} {values.regMonth} {values.regYear}
                  </h4>
                </Grid>
                <Grid item xs={6} sm={4}>
                  <label>Registration No.</label>
                  <h4>{values.regNo}</h4>
                </Grid>
                <Grid item xs={6} sm={4}>
                  <label>Mileage</label>
                  <h4>{values.mileAge} K KM</h4>
                </Grid>
                <Grid item xs={6} sm={4}>
                  <label>ARF</label>
                  <h4>{this.formatMoney(values.arf)}</h4>
                </Grid>
                <Grid item xs={6} sm={4}>
                  <label>No. of Owners</label>
                  <h4>{values.noOwners}</h4>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div className={classes.buttons}>
          <Grid container spacing={24}>
            <Grid item xs={12} sm={4}>
              <Button
                variant="contained"
                className={classes.buttonReset}
                fullWidth
                onClick={this.props.resetStep}
                size="large"
              >
                Start new valuation
              </Button>
            </Grid>
          </Grid>
        </div>
      </form>
    );
  }
  render() {
    return this.renderContainer();
  }
}

export default withStyles(styles)(EstimateResult);
