import React from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  footerLogo: {
    zIndex: 1,
    position: "fixed",
    left: "-50%",
    bottom: 0,
    width: "200%",
    height: "50%",
    "@media (max-width: 480px)": {
      width: "100%"
    }
  },
  imgFooterLogo: {
    height: "100%",
    width: "100%",
    objectFit: "contain",
    opacity: 1,
    "@media (max-width: 480px)": {
      width: "200%"
    }
  }
};

function FooterLogo(props) {
  const { classes } = props;
  return (
    <div className={classes.footerLogo}>
      <img
        alt="Wearnes"
        src="/images/footer_logo.png"
        className={classes.imgFooterLogo}
      />
    </div>
  );
}

export default withStyles(styles)(FooterLogo);
