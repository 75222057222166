import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { CssBaseline } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChooseCarModel from "../ChooseCarModel";
import EstimateDetail from "../EstimateDetail";
import EstimateResult from "../EstimateResult";
import Logo from "components/Logo";
import FooterLogo from "components/FooterLogo";
import Notifier from "components/Notifier";
import MyAppBar from "components/MyAppBar";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

const wearnesTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: '"Lato", "Helvetica", "Arial", sans-serif',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500
  },
  palette: {
    primary: { light: "#B39D69", main: "#B39D69", dark: "#B39D69" },
    secondary: { light: "#7986cb", main: "#007AFF", dark: "#303f9f" }
  }
});

const styles = theme => ({
  appBar: {
    position: "relative"
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 2 * 2)]: {
      width: 1100,
      marginLeft: "auto",
      marginRight: "auto"
    },
    position: "relative",
    zIndex: 2,
    "@media (max-width: 480px)": {
      paddingTop: 56
    }
  },
  toolbarTitle: {
    flex: 1
  },
  toolbarSecondary: {
    justifyContent: "space-between"
  },
  stepper: {
    padding: `${theme.spacing.unit * 3}px 0 ${theme.spacing.unit * 5}px`
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-start"
  },
  breadcrumb: {
    display: "flex",
    justifyContent: "flex-start",
    "@media (max-width: 480px)": {
      display: "none"
    },
    "& span": {
      fontSize: 15,
      color: "#8E8E8E",
      letterSpacing: "-0.19px",
      "&$activated": {
        color: "#FFF"
      },
      padding: "5px 10px",
      cursor: "pointer",
      position: "relative",
      minWidth: "1em",
      minHeight: "1em",
      display: "inlin-block",
      overFlow: "hidden"
    },
    "& :first-child": {
      paddingLeft: 0
    },
    "& svg": {
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      margin: "0 auto",
      left: 0,
      right: 0,
      padding: "0 5px"
    }
  },
  activated: {
    backgroundColor: "#B39D69"
  },
  button: {
    marginTop: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit
  },
  foo: {
    position: "relative",
    background: "linear-gradient(0deg, #E5E5E5 0%, #FFFFFF 100%)",
    minHeight: "100vh",
    paddingBottom: 40
  }
});

class EstimateMain extends React.Component {
  state = {
    step: 0
  };

  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1
    });
  };

  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1
    });
  };

  resetStep = () => {
    this.setState({
      step: 0,
      carModelID: "",
      carModelName: "",
      regDay: "",
      regMonth: "",
      regYear: "",
      mileAge: "",
      regNo: "",
      arf: "",
      noOwners: 1,
      valueCar: "",
      annualDep: ""
    });
  };

  getStepContent = (step, values, results) => {
    switch (step) {
      case 0:
        return (
          <ChooseCarModel
            nextStep={this.nextStep}
            onChoose={this.setValue}
            values={values}
          />
        );
      case 1:
        return (
          <EstimateDetail
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            onChoose={this.setValue}
            onSubmit={this.setResult}
            values={values}
          />
        );
      case 2:
        return (
          <EstimateResult
            results={results}
            values={values}
            resetStep={this.resetStep}
          />
        );
      default:
        throw new Error("Unknown step");
    }
  };

  showBreadcrumb = step => {
    switch (step) {
      case 0:
        return "";
      case 1:
        return (
          <div className={this.props.classes.breadcrumb}>
            <span onClick={this.prevStep}>Select Car Model</span>
            <span>
              <ChevronRightIcon />
            </span>
            <span className={this.props.classes.activated}>Car Details</span>
          </div>
        );
      case 2:
        return (
          <div className={this.props.classes.breadcrumb}>
            <span>Select Car Model</span>
            <span>
              <ChevronRightIcon />
            </span>
            <span>Car Details</span>
            <span>
              <ChevronRightIcon />
            </span>
            <span className={this.props.classes.activated}>
              Recommended Value
            </span>
          </div>
        );
      default:
        throw new Error("Unknown step");
    }
  };

  setValue = (input, value) => {
    this.setState({ [input]: value });
  };

  setResult = (key, value) => {
    this.setState({ [key]: value });
  };

  render() {
    const { classes } = this.props;
    const { step } = this.state;
    const {
      carModelID,
      carModelName,
      regDay,
      regMonth,
      regYear,
      mileAge,
      regNo,
      arf,
      noOwners,
      valueCar,
      annualDep
    } = this.state;
    const values = {
      carModelID,
      carModelName,
      regDay,
      regMonth,
      regYear,
      mileAge,
      regNo,
      arf,
      noOwners
    };

    const results = {
      valueCar,
      annualDep
    };

    // console.log(values, results);

    return (
      <MuiThemeProvider theme={wearnesTheme}>
        <React.Fragment>
          <CssBaseline />
          <MyAppBar
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            currentStep={step}
          />
          <div className={classes.foo}>
            <main className={classes.layout}>
              <Logo />
              <React.Fragment>
                {this.showBreadcrumb(step)}
                {this.getStepContent(step, values, results)}
              </React.Fragment>
            </main>
            <FooterLogo />
          </div>
          <Notifier />
        </React.Fragment>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(EstimateMain);
