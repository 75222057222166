import React from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  logo: {
    height: 70,
    width: 290,
    margin: "0 0 20px",
    paddingTop: 20,
    display: "block",
    "& img": {
      width: "70%",
      height: "100%",
      objectFit: "contain"
    },
    "@media (max-width: 480px)": {
      display: "none",
      width: "100%",
      "& img": {
        width: "70%"
      }
    }
  }
};

function Logo(props) {
  const { classes } = props;
  return (
    <div className={classes.logo}>
      <img alt="Wearnes" src="/images/logo.png" />
    </div>
  );
}

export default withStyles(styles)(Logo);
