import React from "react";
import {
  List,
  Create,
  Edit,
  Datagrid,
  TextField,
  DateField,
  TextInput,
  SimpleForm,
  required
} from "react-admin";
import Icon from "@material-ui/icons/Repeat";
import Title from "./Title";
import { ListActions, ListFilter } from "./ListActions";

export const CarIcon = Icon;

export const CarList = props => (
  <List
    filters={<ListFilter />}
    title="Transactions"
    actions={<ListActions refreshInterval="30000" />}
    bulkActionButtons={false}
    {...props}
  >
    <Datagrid>
      <TextField source="regn_no" />
      <TextField
        source="car_model.name"
        sortBy="car_models.name"
        label="Model"
      />
      <DateField
        source="regn_date"
        options={{ year: "numeric", month: "short", day: "numeric" }}
      />
      <TextField source="mile_age" />
      <TextField source="omv" />
      <TextField source="arf" />
      <TextField source="parf_rebate" />
      <TextField source="no_owners" />
      <TextField source="cost" />
      <TextField source="whole_car" />
      <TextField source="body_price" />
      <DateField
        source="bidding_month"
        options={{ year: "numeric", month: "short", day: "numeric" }}
      />
      <TextField source="years_left" />
      <TextField source="depreciation" />
    </Datagrid>
  </List>
);

export const CarCreate = props => (
  <Create title={"New Car"} {...props}>
    <SimpleForm>
      <TextInput source="regn_no" validate={required()} />
    </SimpleForm>
  </Create>
);

export const CarEdit = props => (
  <Edit title={<Title />} {...props}>
    <SimpleForm>
      <TextInput source="regn_no" validate={required()} />
    </SimpleForm>
  </Edit>
);
