import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { fetchStart, fetchEnd, crudCreate, crudUpdate, SaveButton, showNotification, startUndoable } from 'react-admin';
import initDataProvider from 'utils/dataProvider';
const dataProvider = initDataProvider();

class UploadSaveButtonView extends Component {
  state = {
    uploading: false,
  };

  handleClick = () => {
    const { resource, handleSubmit, fetchStart, fetchEnd, showNotification, redirectTo, push } = this.props;
    return handleSubmit(values => {
      fetchStart();
      this.setState({ uploading: true });
      dataProvider('UPLOAD_FILE', resource, { file: values.file.rawFile })
        .then(({ data }) => {
          // this.save({ ...values, file: null, [source]: data.img_url });
          showNotification(data.message);
          push(redirectTo);
        })
        .catch(error => {
          this.setState({ uploading: false });
          showNotification(error.message, 'warning');
        })
        .finally(fetchEnd);
    });
  };

  render() {
    const {
      handleSubmitWithRedirect,
      crudCreate,
      dispatchCrudUpdate,
      startUndoable,
      fetchStart,
      fetchEnd,
      showNotification,
      source,
      resource,
      saving,
      undoable,
      redirectTo,
      push,
      ...props
    } = this.props;

    return (
      <SaveButton
        saving={saving || (this.state.uploading ? { redirect: props.redirectTo } : false)}
        handleSubmitWithRedirect={this.handleClick}
        {...props}
      />
    );
  }
}

UploadSaveButtonView.defaultProps = {
  undoable: true,
};

export default connect(
  undefined,
  {
    crudCreate,
    dispatchCrudUpdate: crudUpdate,
    showNotification,
    fetchStart,
    fetchEnd,
    startUndoable,
    push,
  },
)(UploadSaveButtonView);
