import { stringify } from "qs";
import {
  fetchUtils,
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY
} from "react-admin";

/**
 * Maps react-admin queries to REST API server
 *
 * @example
 * GET_LIST     => GET http://my.api.url/posts?s=title&o=ASC&p=1&l=20
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts/123|456|789
 * UPDATE       => PATCH http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts/123
 * DELETE       => DELETE http://my.api.url/posts/123
 */
export default () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const apiVer = process.env.REACT_APP_API_VERSION;
  const GET_LIST_CUSTOM = "GET_LIST_CUSTOM";
  const httpClient = (url, options = {}) => {
    if (!options.headers) {
      options.headers = new Headers();
    }
    if (!options.headers.has("Accept")) {
      options.headers.set("Accept", "application/json");
    }
    const token = localStorage.getItem("token");
    options.headers.set("Authorization", `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
  };

  /**
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} { url, options } The HTTP request parameters
   */
  const convertDataRequestToHTTP = (type, resource, params) => {
    let url = "";
    const options = {};
    switch (type) {
      case GET_LIST: {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
          f: JSON.stringify(params.filter),
          s: field,
          o: order,
          p: page,
          l: perPage
        };
        url = `${apiUrl}/${apiVer}/${resource}?${stringify(query)}`;
        break;
      }
      case GET_ONE:
        url = `${apiUrl}/${apiVer}/${resource}/${params.id}`;
        break;
      case GET_MANY_REFERENCE: {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
          f: JSON.stringify({ ...params.filter, [params.target]: params.id }),
          s: field,
          o: order,
          p: page,
          l: perPage
        };
        url = `${apiUrl}/${apiVer}/${resource}?${stringify(query)}`;
        break;
      }
      case GET_LIST_CUSTOM: {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
          f: JSON.stringify(params.filter),
          s: field,
          o: order,
          p: page,
          l: perPage
        };
        url = `${apiUrl}/${apiVer}/${resource}?${stringify(query)}`;
        break;
      }
      case UPDATE:
        url = `${apiUrl}/${apiVer}/${resource}/${params.id}`;
        options.method = "PATCH";
        options.body = JSON.stringify(params.data);
        break;
      case CREATE:
        url = `${apiUrl}/${apiVer}/${resource}`;
        options.method = "POST";
        options.body = JSON.stringify(params.data);
        break;
      case DELETE:
        url = `${apiUrl}/${apiVer}/${resource}/${params.id}`;
        options.method = "DELETE";
        break;
      case GET_MANY: {
        const query = {
          f: JSON.stringify({ id: params.ids })
        };
        url = `${apiUrl}/${apiVer}/${resource}?${stringify(query)}`;
        break;
      }
      case "CALCULATE":
        url = `${apiUrl}/${apiVer}/${resource}`;
        options.method = "POST";
        options.body = JSON.stringify(params.data);
        break;
      case "UPLOAD_IMAGE": {
        const data = new FormData();
        data.append("file", params.file);
        data.append("key", resource);
        options.method = "POST";
        options.body = data;
        url = `${apiUrl}/${apiVer}/uploads/img`;
        break;
      }
      case "UPLOAD_FILE": {
        const data = new FormData();
        data.append("file", params.file);
        options.method = "POST";
        options.body = data;
        url = `${apiUrl}/${apiVer}/uploads/xlsx`;
        break;
      }
      default:
        throw new Error(`Unsupported fetch action type ${type}`);
    }
    return { url, options };
  };

  /**
   * @param {Object} response HTTP response from fetch()
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} Data response
   */
  const convertHTTPResponse = (response, type, resource, params) => {
    const { json } = response;
    switch (type) {
      case GET_LIST:
      case GET_MANY:
      case GET_MANY_REFERENCE:
        return {
          data: json.data,
          total: json.pagination.count
        };
      case GET_LIST_CUSTOM:
        return {
          data: json.data,
          pagination: json.pagination
        };
      case CREATE:
        return { data: { ...params.data, id: json.id } };
      default:
        return { data: json };
    }
  };

  /**
   * @param {string} type Request type, e.g GET_LIST
   * @param {string} resource Resource name, e.g. "posts"
   * @param {Object} payload Request parameters. Depends on the request type
   * @returns {Promise} the Promise for a data response
   */
  return (type, resource, params) => {
    // json-server doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
    if (type === UPDATE_MANY) {
      return Promise.all(
        params.ids.map(id =>
          httpClient(`${apiUrl}/${apiVer}/${resource}/${id}`, {
            method: "PATCH",
            body: JSON.stringify(params.data)
          })
        )
      ).then(responses => ({
        data: responses.map(response => response.json)
      }));
    }
    // json-server doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    if (type === DELETE_MANY) {
      return Promise.all(
        params.ids.map(id =>
          httpClient(`${apiUrl}/${apiVer}/${resource}/${id}`, {
            method: "DELETE"
          })
        )
      ).then(responses => ({
        data: responses.map(response => response.json)
      }));
    }
    const { url, options } = convertDataRequestToHTTP(type, resource, params);
    return httpClient(url, options).then(response =>
      convertHTTPResponse(response, type, resource, params)
    );
  };
};
