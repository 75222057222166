import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  TextField,
  FormHelperText,
  InputAdornment
} from "@material-ui/core";
import moment from "moment";
import classNames from "classnames";
import { openSnackbar } from "components/Notifier";
import initDataProvider from "utils/dataProvider";
const dataProvider = initDataProvider();

const styles = theme => ({
  root: {
    width: "100%"
  },
  formControl: {
    margin: theme.spacing.unit
  },
  buttons: {
    marginTop: 20
  },
  labelW: {
    display: "block",
    width: "100%",
    color: "#474747",
    fontSize: "0.875em",
    letterSpacing: "-0.17px",
    textAlign: "left",
    paddingTop: 15,
    marginTop: 15,
    marginBottom: 5
  },
  carmodelW: {
    color: "#000000",
    fontSize: "1.375em",
    fontWeight: "700",
    letterSpacing: "-0.45px",
    margin: "15px 0"
  },
  buttonW: {
    minWidth: 160,
    color: "#B39D69",
    borderColor: "#B39D69"
  },
  borderTopW: {
    borderTop: "1px solid #DDDEE0"
  },
  inputW: {
    backgroundColor: "#FFF",
    padding: "10px 20px",
    "& :focus": {
      backgroundColor: "#FFF"
    },
    "@media(max-width: 480px)": {
      padding: "10px"
    },
    "& select": {
      width: "calc(100% - 25px)"
    }
  }
});

class EstimateDetail extends Component {
  handleInput = input => event => {
    let value = "";
    switch (input) {
      case "mileAge":
        value = parseFloat(event.target.value);
        break;
      case "arf":
        value = parseFloat(event.target.value);
        break;
      case "noOwners":
        value = parseInt(event.target.value);
        break;
      default:
        value = event.target.value;
        break;
    }
    this.props.onChoose(input, value);
  };

  handleSubmit = () => {
    this.validateForm(this.props.values);
  };

  getMonthNum(monthStr) {
    return moment()
      .month(monthStr)
      .format("MM");
  }

  validateForm = values => {
    if (values.regDay == null || values.regDay === "") {
      openSnackbar({ message: "Please choose Day", type: "error" });
    } else if (values.regMonth == null || values.regMonth === "") {
      openSnackbar({ message: "Please choose Month", type: "error" });
    } else if (values.regYear == null || values.regYear === "") {
      openSnackbar({ message: "Please choose Year", type: "error" });
    } else if (values.mileAge == null || values.mileAge === "") {
      openSnackbar({ message: "Please enter Mileage", type: "error" });
    } else if (values.arf == null || values.arf === "") {
      openSnackbar({ message: "Please enter ARF", type: "error" });
    } else if (values.noOwners === 0) {
      openSnackbar({
        message: "Please enter a valid No. of Owners",
        type: "error"
      });
    } else {
      this.postData(values);
    }
  };

  postData(values) {
    const regDate =
      values.regYear +
      "-" +
      this.getMonthNum(values.regMonth) +
      "-" +
      values.regDay.padStart(2, "0") +
      "T00:00:00Z";

    if (values.noOwners == null) {
      this.props.onChoose("noOwners", 1);
    }

    dataProvider("CALCULATE", "pub/calculators", {
      data: {
        arf: values.arf,
        car_model_id: values.carModelID,
        mile_age: values.mileAge,
        no_owners: values.noOwners,
        regn_date: regDate,
        regn_no: values.regNo
      }
    })
      .then(({ data }) => {
        this.props.onSubmit("valueCar", data.value_car);
        this.props.onSubmit("annualDep", data.annual_depreciation);
        this.props.nextStep();
      })
      .catch(error => {
        openSnackbar({ message: error, type: "error" });
        // this.setState({ uploading: false });
      });
  }

  renderContainer() {
    const { values, classes } = this.props;
    const year = new Date().getFullYear();
    this.years = Array.from(new Array(20), (val, index) => year - index);

    this.months = moment.monthsShort();
    this.days = Array.from(new Array(31), (val, index) => index + 1);

    return (
      <form className={classNames(classes.root)} autoComplete="off">
        <label className={classes.labelW}>Car Model</label>
        <h6 className={classes.carmodelW}>{values.carModelName}</h6>
        <label className={classNames(classes.labelW, classes.borderTopW)}>
          Registration Date
        </label>
        <Grid container spacing={24}>
          <Grid item xs={4} sm={2}>
            <TextField
              className={classes.inputW}
              onChange={this.handleInput("regDay")}
              select
              SelectProps={{
                name: "regDay",
                id: "regDay",
                native: true,
                disableUnderline: true
              }}
              fullWidth
            >
              <option value="">Day</option>
              {this.days.map((day, index) => {
                return (
                  <option key={`day${index}`} value={day}>
                    {day}
                  </option>
                );
              })}
            </TextField>
          </Grid>
          <Grid item xs={4} sm={2}>
            <TextField
              className={classes.inputW}
              onChange={this.handleInput("regMonth")}
              select
              SelectProps={{
                name: "regMonth",
                id: "regMonth",
                native: true,
                disableUnderline: true
              }}
              fullWidth
            >
              <option value="">Month</option>
              {this.months.map((month, index) => {
                return (
                  <option key={`month${index}`} value={month}>
                    {month}
                  </option>
                );
              })}
            </TextField>
          </Grid>
          <Grid item xs={4} sm={2}>
            <TextField
              className={classes.inputW}
              onChange={this.handleInput("regYear")}
              select
              SelectProps={{
                name: "regYear",
                id: "regYear",
                native: true,
                disableUnderline: true
              }}
              fullWidth
            >
              <option value="">Year</option>
              {this.years.map((year, index) => {
                return (
                  <option key={`year${index}`} value={year}>
                    {year}
                  </option>
                );
              })}
            </TextField>
          </Grid>
        </Grid>
        <label className={classes.labelW}>Mileage</label>
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <TextField
              className={classes.inputW}
              type="number"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">K KM</InputAdornment>
                ),
                inputProps: {
                  name: "mileAge",
                  id: "mileAge",
                  min: "0"
                },
                disableUnderline: true
              }}
              onChange={this.handleInput("mileAge")}
              placeholder="0"
            />
          </Grid>
        </Grid>
        <label className={classes.labelW}>
          Registration No.{" "}
          <FormHelperText component="span">(Optional)</FormHelperText>
        </label>
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <TextField
              className={classes.inputW}
              fullWidth
              InputProps={{
                name: "regNo",
                id: "regNo",
                disableUnderline: true
              }}
              onChange={this.handleInput("regNo")}
              placeholder="e.g. SFNXXXXG"
            />
          </Grid>
        </Grid>
        <label className={classes.labelW}>ARF</label>
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <TextField
              className={classes.inputW}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      variant="outlined"
                      className={classes.buttonW}
                      onClick={() =>
                        window.open("https://www.onemotoring.com.sg", "_blank")
                      }
                    >
                      One Motoring
                    </Button>
                  </InputAdornment>
                ),
                disableUnderline: true,
                inputProps: {
                  name: "arf",
                  id: "arf",
                  min: "0"
                }
              }}
              type="number"
              onChange={this.handleInput("arf")}
              placeholder="0"
            />
          </Grid>
        </Grid>
        <label className={classes.labelW}>No. of Owners</label>
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <TextField
              className={classes.inputW}
              fullWidth
              InputProps={{
                inputProps: {
                  name: "noOwners",
                  id: "noOwners",
                  min: "1"
                },
                disableUnderline: true
              }}
              onChange={this.handleInput("noOwners")}
              type="number"
              placeholder="1"
              defaultValue="1"
            />
          </Grid>
        </Grid>
        <div className={classes.buttons}>
          <Grid container spacing={24}>
            <Grid item xs={12} sm={4}>
              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                fullWidth
                onClick={this.handleSubmit}
                size="large"
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </div>
      </form>
    );
  }
  render() {
    return this.renderContainer();
  }
}

export default withStyles(styles)(EstimateDetail);
