import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TablePagination as MuiTablePagination,
  Grid,
  TextField,
  InputAdornment
} from "@material-ui/core";
import EnhancedTableHead from "components/EnhancedTableHead";
import initDataProvider from "utils/dataProvider";
import SearchIcon from "@material-ui/icons/Search";

const TablePagination = withStyles({
  "@media (max-width: 480px)": {
    selectRoot: { marginRight: 8 },
    actions: { marginLeft: 5 }
  }
})(MuiTablePagination);
const dataProvider = initDataProvider();

const styles = theme => ({
  root: {
    marginTop: theme.spacing.unit * 3
  },
  table: {
    minWidth: 400,
    width: "100%"
  },
  thWearnes: {
    color: "#B39D69",
    paddingLeft: "20px !important",
    "&:hover,&:active": {
      color: "#B39D69"
    },
    fontSize: 16
  },
  tdWearnes: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#B39D69",
      color: "white"
    },
    fontSize: 16,
    backgroundColor: "#FFF"
  },
  tfWearnes: {
    backgroundColor: "#FFF"
  },
  inputSearch: {
    marginBottom: 20,
    backgroundColor: "#FFF",
    padding: "10px 20px"
  },
  tableWrapper: {
    overflowX: "auto"
  },
  tablePaginationWrapper: {
    backgroundColor: "#FFF",
    fontSize: ".75rem"
  }
});

class ChooseCarModel extends Component {
  state = {
    data: [],
    page: 0,
    rowsPerPage: 25,
    count: 0,
    searchString: "",
    order: "ASC",
    orderBy: "name"
  };

  componentDidMount() {
    this.getData(
      this.state.page,
      this.state.rowsPerPage,
      this.state.orderBy,
      this.state.order,
      this.state.searchString
    );
  }

  // componentWillUnmount() {
  //   this.setState({
  //     data: [],
  //     page: 1,
  //     rowsPerPage: 25,
  //     count: 0,
  //     searchString: "",
  //     order: "ASC",
  //     orderBy: "name"
  //   });
  // }

  getData(page, rowsPerPage, orderBy, order, searchString) {
    // console.log(page, rowsPerPage, orderBy, order, searchString);
    dataProvider("GET_LIST_CUSTOM", "pub/carmodels", {
      filter: { f: searchString },
      pagination: {
        page: page,
        perPage: rowsPerPage
      },
      sort: { field: orderBy, order: order }
    })
      .then(({ data, pagination }) => {
        this.setState({
          data: data,
          page: pagination.page,
          rowsPerPage: rowsPerPage,
          count: pagination.count,
          searchString: searchString
        });
      })
      .catch(error => {
        console.log(error);
        // this.setState({ uploading: false });
      });
  }

  handleChangePage = (event, page) => {
    this.getData(
      page + 1,
      this.state.rowsPerPage,
      this.state.orderBy,
      this.state.order,
      this.state.searchString
    );
  };

  handleChangeRowsPerPage = event => {
    this.getData(
      this.state.page,
      event.target.value,
      this.state.orderBy,
      this.state.order,
      this.state.searchString
    );
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "DESC";

    if (this.state.orderBy === property && this.state.order === "DESC") {
      order = "ASC";
    }

    this.getData(
      this.state.page,
      this.state.rowsPerPage,
      this.state.orderBy,
      order,
      this.state.searchString
    );
    this.setState({ order, orderBy });
  };

  handleClick = (id, name) => () => {
    this.props.onChoose("carModelID", id);
    this.props.onChoose("carModelName", name);
    this.props.nextStep();
  };

  handleSearch = event => {
    this.getData(
      this.state.page,
      this.state.rowsPerPage,
      this.state.orderBy,
      this.state.order,
      event.target.value
    );
    // this.setState({ searchString: event.target.value });
  };

  renderContainer() {
    const { classes } = this.props;
    const { data, rowsPerPage, page, count, order, orderBy } = this.state;
    // const emptyRows =
    //   rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);

    const tableHead = [
      { id: "name", numeric: false, disablePadding: true, label: "Model No." }
    ];

    // console.log(rowsPerPage, page, count, order, orderBy);

    return (
      <div className={classes.root}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6}>
            <TextField
              className={classes.inputSearch}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
                inputProps: {
                  name: "searchCar",
                  id: "searchCar"
                },
                disableUnderline: true
              }}
              placeholder="Enter a car model"
              onChange={this.handleSearch}
            />
          </Grid>
        </Grid>
        <div className={classes.tableWrapper}>
          <Table className={classes.table}>
            <EnhancedTableHead
              order={order.toLowerCase()}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
              rows={tableHead}
              className={classes.thWearnes}
            />
            <TableBody>
              {data.map(row => {
                return (
                  <TableRow hover key={row.id}>
                    <TableCell
                      className={classes.tdWearnes}
                      component="th"
                      scope="row"
                      onClick={this.handleClick(row.id, row.name)}
                    >
                      {row.name}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          rowsPerPage={rowsPerPage}
          component="div"
          count={count}
          page={page - 1}
          backIconButtonProps={{
            "aria-label": "Previous Page"
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page"
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          className={classes.tablePaginationWrapper}
        />
      </div>
    );
  }

  render() {
    return this.renderContainer();
  }
}

export default withStyles(styles)(ChooseCarModel);
