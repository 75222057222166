import React from 'react';
import { Admin, Resource } from 'react-admin';
import initDataProvider from './utils/dataProvider';
import initAuthProvider from './utils/authProvider';
import { CarModelIcon, CarModelList, CarModelCreate } from './containers/CarModel';
import { CarIcon, CarList } from './containers/Car';
import { FileIcon, FileList } from './containers/File';
import { SettingIcon, SettingList, SettingEdit } from './containers/Setting';
import MyLayout from './MyLayout';
import customRoutes from './customRoutes';

const dataProvider = initDataProvider();
const authProvider = initAuthProvider();

const App = () => (
  <Admin customRoutes={customRoutes} appLayout={MyLayout} authProvider={authProvider} dataProvider={dataProvider}>
    <Resource
      icon={CarIcon}
      name="cars"
      options={{ label: 'Transactions' }}
      list={CarList}
      //create={CarCreate}
      //edit={CarEdit}
    />
    <Resource
      icon={CarModelIcon}
      name="carmodels"
      options={{ label: 'Models' }}
      list={CarModelList}
      create={CarModelCreate}
      //edit={CarModelEdit}
    />
    <Resource
      icon={FileIcon}
      name="files"
      options={{ label: 'Upload Histories' }}
      list={FileList}
    />
    <Resource
      icon={SettingIcon}
      name="settings"
      options={{ label: 'Settings' }}
      list={SettingList}
      edit={SettingEdit}
    />
  </Admin>
);
  
export default App;