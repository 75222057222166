import React from 'react';
import {
  List,
  Datagrid,
  DateField,
  UrlField
} from 'react-admin';
import Icon from '@material-ui/icons/History';

export const FileIcon = Icon;

const SizeField = ({ record = {} }) => <span>{niceBytes(record.bytes)}</span>;
SizeField.defaultProps = { label: 'Size' };

const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

function niceBytes(x){
  let l = 0, n = parseInt(x, 10) || 0;
  while(n >= 1024 && ++l)
      n = n/1024;
  return(n.toFixed(n >= 10 || l < 1 ? 0 : 1) + ' ' + units[l]);
}

export const FileList = props => (
  <List title="Upload Histories" bulkActionButtons={false} {...props}>
    <Datagrid>
      <DateField source="created_at" showTime={true} options={{ year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' }}  />
      <SizeField source="bytes" />
      <UrlField source="url" />
    </Datagrid>
  </List>
);