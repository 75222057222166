import React from 'react';
import { CardActions, RefreshButton, ExportButton, Filter, TextInput } from 'react-admin';

export const ListActions = ({ basePath, data, resource, currentSort, filterValues, exporter }) => {
  return (
    <CardActions>
      <RefreshButton />
      <ExportButton resource={resource}
            sort={currentSort}
            filter={filterValues}
            exporter={exporter} />
    </CardActions>
  );
};

export const ListFilter = (props) => (
  <Filter {...props}>
      <TextInput label="Search" source="f" alwaysOn />
      <TextInput label="Model" source="model" />
  </Filter>
);

