import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import BackIcon from "@material-ui/icons/NavigateBefore";

const styles = {
  appBar: {
    flexGrow: 1,
    "@media (min-width: 480px)": { display: "none" }
  },
  menuButton: {
    paddingLeft: 0
  },
  header: {
    marginRight: 36,
    width: "calc(100% - 36px)",
    textAlign: "center",
    textTransform: "uppercase",
    fontSize: 16,
    marginTop: 0,
    marginBottom: 0
  },
  hidden: {
    opacity: "0"
  }
};

class MyAppBar extends React.Component {
  displayTitle = step => {
    switch (step) {
      case 2:
        return "Results";
      default:
        return "Valuation";
    }
  };

  displayBackIcon = step => {
    switch (step) {
      case 0:
        return (
          <IconButton
            className={this.props.classes.menuButton}
            color="inherit"
            aria-label="Back"
          >
            <BackIcon className={this.props.classes.hidden} />
          </IconButton>
        );
      default:
        return (
          <IconButton
            className={this.props.classes.menuButton}
            color="inherit"
            aria-label="Back"
            onClick={this.props.prevStep}
          >
            <BackIcon />
          </IconButton>
        );
    }
  };
  render() {
    const { classes, currentStep } = this.props;
    return (
      <div className={classes.appBar}>
        <AppBar position="fixed" color="default">
          <Toolbar>
            {this.displayBackIcon(currentStep)}
            <h6 color="inherit" className={this.props.classes.header}>
              {this.displayTitle(currentStep)}
            </h6>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

MyAppBar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(MyAppBar);
